var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footmark"},[_c('div',{staticClass:"f-c-b"},[_c('el-page-header',{attrs:{"content":"我的足迹列表"},on:{"back":_vm.backPrivious}}),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.list && _vm.list.length),expression:"list && list.length"}],attrs:{"type":"primary"},on:{"click":_vm.removeAllFootmarks}},[_c('i',{staticClass:"el-icon-delete"})])],1),(_vm.list && _vm.list.length)?[_vm._l((_vm.list),function(item,index){return _c('router-link',{key:index,staticClass:"padding-10 f-c f1 hover",attrs:{"to":{
            name: 'ProductDetail',
            query: {
                key: _vm.$UrlEncode.encode(JSON.stringify({
                    id: item.productId
                }))
            }
        }}},[_c('el-image',{staticClass:"borderRa-10 mr-20 wh-100",attrs:{"src":item.img1Url}}),_c('div',{staticClass:"f-cl favorites-info"},[_c('span',{staticClass:"mb-10"},[_vm._v(_vm._s(item.productName))]),_c('span',{staticClass:"col-red fs-14"},[_vm._v(_vm._s(item.unitPrice ? (item.valuationCurrency + item.unitPrice) : '暂无报价'))])])],1)}),_c('Pagination',{attrs:{"total":_vm.total,"pageNum":_vm.currentPage,"limit":_vm.size,"layout":_vm.layout},on:{"update:pageNum":function($event){_vm.currentPage=$event},"update:page-num":function($event){_vm.currentPage=$event},"update:limit":function($event){_vm.size=$event},"pagination":_vm.fetchData}})]:_c('el-empty',{attrs:{"description":"暂无内容"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }