<template>
    <div class="footmark">
        <div class="f-c-b">
            <el-page-header @back="backPrivious" content="我的足迹列表"></el-page-header>
            <el-button v-show="list && list.length" type="primary" @click="removeAllFootmarks"><i
                    class="el-icon-delete"></i></el-button>
        </div>
        <template v-if="list && list.length">
            <router-link :to="{
                name: 'ProductDetail',
                query: {
                    key: $UrlEncode.encode(JSON.stringify({
                        id: item.productId
                    }))
                }
            }" class="padding-10 f-c f1 hover" v-for="(item, index) of list" :key="index">
                <el-image class="borderRa-10 mr-20 wh-100" :src="item.img1Url"></el-image>
                <div class="f-cl favorites-info ">
                    <span class="mb-10">{{ item.productName }}</span>
                    <span class="col-red fs-14">{{ item.unitPrice ? (item.valuationCurrency + item.unitPrice) : '暂无报价'
                    }}</span>
                </div>
            </router-link>
            <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                @pagination="fetchData" />
        </template>
        <el-empty v-else description="暂无内容"></el-empty>
    </div>
</template>

<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
export default {
    name: 'footmark',
    mixins: [PaginationRetention],
    data() {
        return {
            list: []
        }
    },
    methods: {
        getDate() {
            this.productFootmarksList(this.paging, this.pagingSize)
        },
        // 收藏列表
        productFootmarksList(page = this.page, size = this.size) {
            let that = this;
            that.$http.productFootmarksList({
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    that.list = res.data.records;
                    that.total = res.data.total;
                    that.currentPage = res.data.current;
                }
            })
        },
        // 移除全部浏览足迹接口
        removeAllFootmarks() {
            let that = this;
            that.$confirm('是否清除全部浏览足迹？', '提示').then(() => {
                that.$http.removeAllFootmarks({
                    lastModifyUserId: parseInt(that.$store.state.userId),
                }).then(res => {
                    if (res.code == 200) {
                        that.list = [];
                        that.total = 0;
                        that.currentPage = 0;
                        that.$common.message(res.msg, 'success', 1200)
                    }
                })
            }).catch(() => { })
        }
    },
}

</script>
